
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { themeLightLogoIconText } from "@/core/helpers/config";
import { I18nUtils } from "@/sea/utils/I18nUtils";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email(I18nUtils.translate("sea.pages.signIn.emailNotValid"))
        .required(I18nUtils.translate("sea.pages.signIn.emailRequired"))
        .label("Email"),
      password: Yup.string()
        .min(4, I18nUtils.translate("sea.pages.signIn.passwordNotLongEnough"))
        .required(I18nUtils.translate("sea.pages.signIn.passwordRequired"))
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.disabled = true;
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Clear existing errors
      await store.dispatch(Actions.LOGOUT);

      // Send login request
      await store.dispatch(Actions.LOGIN, values);

      if (submitButton.value) {
        //Deactivate indicator
        submitButton.value.removeAttribute("data-kt-indicator");
        submitButton.value.disabled = false;
      }

      const [error] = Object.keys(store.getters.getErrors);

      if (!error) {
        await router.push({ name: "dashboard" });
      } else {
        Swal.fire({
          text: I18nUtils.translateGlobal(store.getters.getErrors[error]),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: I18nUtils.translateGlobal(
            "sea.pages.signIn.tryAgain"
          ),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      themeLightLogoIconText,
      I18nUtils,
    };
  },
});
